import React from 'react'
import { useRef  , useState } from 'react';
import emailjs from 'emailjs-com';
import axios from "axios";

import '../../assets/css/our_values.css'; 
import '../../assets/css/contact_sec.css'; 

export default function Contact_sec() {

    const form = useRef();
    const [loading, setLoading] = useState(false)
    const [statusMessage, setStatusMessage] = useState("");
    
    const nameinput = React.useRef(null);
    const companyinput = React.useRef(null);
    const emailinput = React.useRef(null);
    const phoneinput = React.useRef(null);
    const serviceinput = React.useRef(null);
    const msginput = React.useRef(null);


    // All field validation error hide code 
    const handleFocus = e => {
        e.target.classList.remove("error_line");

        let er1 = document.getElementById("nm_err");
        er1.classList.remove("show_error");
        
        let er2 = document.getElementById("cmp_err");
        er2.classList.remove("show_error");

        let er3 = document.getElementById("eml_err");
        er3.classList.remove("show_error");    

        let er5 = document.getElementById("srvc_err");
        er5.classList.remove("show_error");

        let er6 = document.getElementById("msg_err");
        er6.classList.remove("show_error");

    };

    // Phone field validation error hide code    
    const handleFocus1 = e => {
        e.target.classList.remove("error_line");

        let er4 = document.getElementById("phn_err");
        er4.classList.remove("show_error");

        let er4v = document.getElementById("phn_err1");
        er4v.classList.remove("show_error");    
    };
    
    // Form action code
    const sendEmail = (e) => {
        e.preventDefault();


        
        

        // Fields get value Code
        let name_fld= (nameinput.current.value);
        let company_fld= (companyinput.current.value);
        let email_fld= (emailinput.current.value);
        let phone_fld= (phoneinput.current.value);
        let service_fld= (serviceinput.current.value);
        let msg_fld= (msginput.current.value);


        
        // Phone Regex Code
        let phone_fltr = document.getElementById("phone_fld");
        var mob_regx = /^(\+\d{1,3}[- ]?)?\d{1,99}$/;

            
            
     
        // Fields validation Code
        if(name_fld == '')
        {
            let v1 = document.getElementById("name_fld");
            v1.className += " error_line";

            let er1 = document.getElementById("nm_err");
            er1.className += " show_error";
        }
        
        else if(company_fld == '')
        {
            let v2 = document.getElementById("company_fld");
            v2.className += " error_line";  
            
            let er2 = document.getElementById("cmp_err");
            er2.className += " show_error";
        }
        else if(email_fld == '')
        {
            let v3 = document.getElementById("email_fld");
            v3.className += " error_line";

            let er3 = document.getElementById("eml_err");
            er3.className += " show_error";
        }
        else if(phone_fld == '')
        {
            let v4 = document.getElementById("phone_fld");
            v4.className += " error_line";

            let er4 = document.getElementById("phn_err");
            er4.className += " show_error";
        }
        else if (!mob_regx.test(phone_fltr.value)) {
            let v4 = document.getElementById("phone_fld");
            v4.className += " error_line";

            let er4 = document.getElementById("phn_err1");
            er4.className += " show_error";
        }
        else if(service_fld == '')
        {
            let v5 = document.getElementById("service_fld");
            v5.className += " error_line";

            let er5 = document.getElementById("srvc_err");
            er5.className += " show_error";
        }
        else if(msg_fld == '')
        {
            let v6 = document.getElementById("msg_fld");
            v6.className += " error_line";

            let er6 = document.getElementById("msg_err");
            er6.className += " show_error";
        }
        else
        {

            fetch('https://script.google.com/macros/s/AKfycbzraxEYGDeQjdpwyUyQeBs0rCaKiEIp635ldQf1PYwPCR8tvOdhX7xoiNDM15XqCX-U/exec', {
                method: 'POST', 
                body: new FormData(form.current),
            
            }).then(res => {
                console.log("SUCCESSFULLY SUBMITTED")
                setLoading(false)
            })
            .catch(err => console.log(err))
            
            emailjs.sendForm('service_8keqc5e', 'template_nhxyute', e.target, 'SsPYHKCapw4h-xBn_')
            .then((result) => {

                console.log(result.text);
                // setStatusMessage("Your Message has been sent successfully");
                let bx = document.getElementById("thnk_box");
                bx.className += " thnk_show";

                let frm_bx = document.getElementById("form_bx");
                frm_bx.className += " form_hide";

            }, (error) => {

                console.log(error.text);
                setStatusMessage(`${error.text} happened`);

            });  
            e.target.reset()   
           

            

        }
    };


  return (
    <>
      <div className='vlus_main' id='contact-pg'>
          <div className='row vlus_row'>
              <div className='col-lg-6 vlue-col1 cont-col1'>
                  <div className='cont-sb-dv'>
                        <h2 className='vlue-ttl cont-ttl'>India - Mumbai (Head Office)</h2>
                        <p className='cont-sub-ttl'>Capricorn Logistics Pvt. Ltd.</p>  
                        <ul className='contact-list'>
                            <li className='address'><span>Add </span>: 13/13 A, Keytuo Industrial Estate, 220,<br/>
                            Kondivita Road, Andheri (East) Mumbai – 400059</li>
                            <li className='tele-no'><span>Tel </span>:<a href='tel:+ 02269846000'> + 022 6984 6000</a></li>
                            <li className='tele-no'><span>Fax </span>:<a href='tel:+ 912240706199'> + 91 22 4070 6199</a></li>
                        </ul>
                  </div>
              </div>
              <div className='col-lg-6 vlue-col2'>
                <img className='vlue_img' src={require('../../assets/img/Contact.png')}></img>
              </div>  
          </div>
          <div className='cstm-container'>
              <div className='row cntct-frm-row' id='get-in-touch'>
                  <div className='col-12 contact-frm-col'>
                        <h2 className='cntct-ttl'>Get In Touch</h2>
                        
                        <form ref={form} onSubmit={sendEmail} className='form_bx' id='form_bx'>

                            <div className='fld_dv'>
                                <input type='text' className='input-fld' id='name_fld' placeholder='Name' name='name_fld'  ref={nameinput} onFocus={handleFocus} ></input>
                                <p className='error_msg' id='nm_err'>Please Enter Name</p>
                            </div>
                            
                            <div className='fld_dv'>
                                <input type='text' className='input-fld' id='company_fld' placeholder='Company' name='company_fld' ref={companyinput} onFocus={handleFocus}></input>
                                <p className='error_msg' id='cmp_err'>Please Enter Company Name</p>
                            </div>

                            <div className='fld_dv'>
                                <input type='email' className='input-fld' id='email_fld'  placeholder='Email' name='email_fld' ref={emailinput} onFocus={handleFocus}></input>
                                <p className='error_msg' id='eml_err'>Please Enter Email</p>
                            </div>

                            <div className='fld_dv'>
                                <input type='text' className='input-fld' id='phone_fld' placeholder='Phone' name='phone_fld' ref={phoneinput} onFocus={handleFocus1}></input>
                                <p className='error_msg' id='phn_err'>Please Enter Phone Numbers</p>
                                <p className='error_msg' id='phn_err1'>Please Enter Valid Phone Numbers</p>
                            </div>

                            <div className='fld_dv srvc_dv'>
                                <select className='input-fld' id='service_fld' name='service_fld' ref={serviceinput} onFocus={handleFocus}>
                                    <option value="" disabled selected>Select Service</option>
                                    <option value="Redefining Global Freight Forwarding Solutions">Redefining Global Freight Forwarding Solutions</option>
                                    <option value="Integrated Supply Chain Solutions">Integrated Supply Chain Solutions</option>
                                    <option value="Customs Clearance and Insurance">Customs Clearance and Insurance</option>
                                    <option value="Industrial & Infactory Solutions">Industrial & Infactory Solutions</option>
                                    <option value="Value Added Services">Value Added Services</option>
                                </select>
                                <p className='error_msg' id='srvc_err'>Please Select Service</p>
                            </div>

                            <div className='fld_dv1'>
                                <textarea className='txtarea' id='msg_fld' placeholder='Comment' name='msg_fld' ref={msginput} onFocus={handleFocus}></textarea>
                                <p className='error_msg' id='msg_err'>Please Enter Comment</p>
                            </div>
                            
                            <input type='submit' value='Submit' className='submit-btn rd-btn'></input>

                        </form>
                        {/* <p>{statusMessage}</p> */}
                        <p className='thnk' id='thnk_box'>Your Message has been sent successfully</p>
                  </div>
              </div>
          </div>
      </div>
    </>
  )
}